@import 'src/utils/utils';

.aside-baner {
  position: sticky;
  top: 30px;
  padding: 25px;
  border-radius: 20px;
  color: $Main-2;
  overflow: hidden;

  &__banner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: 0;
    padding: 0;
    background-color: $Main-6;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h2 {
    @include Head-35-bold;
    margin: 0;
    margin-bottom: 15px;
  }

  p {
    @include Text-16-reg;
    margin: 0;
    margin-top: 10px;
  }
}